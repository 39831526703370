import React, { useState, useEffect, useRef } from "react";
import Countdown from "./Countdown";
import handleClaim from "../Utilities/handleClaim";
import { useWalletContext } from "../context/WalletProvider";
import CardClaimHeaderImg from "./atoms/CardClaimHeaderImg";
import LoadingGif from "./atoms/LoadingGif";
import { useNotification } from "../context/NotificationContext";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../config";
import { ReactSVG } from "react-svg";

const Collection = (props) => {
  const navigate = useNavigate();
  let { name } = useParams();
  if (name === undefined) {
    name = props.cardName;
  }
  const [cardName, setCardName] = useState(name);

  const { notifications, setNotifications } = useNotification();
  const { connected, usersTaprootAddress, PayInvoice } = useWalletContext();
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [minted, setMinted] = useState(0);
  const [opensAt, setOpensAt] = useState(0);
  const [closesAt, setClosesAt] = useState(0);
  const [maxPublicClaim, setMaxPublicClaim] = useState(5);
  const [enabled, setEnabled] = useState(false);
  const [showOpeningCountdown, setShowOpeningCountdown] = useState(false);
  const [timeTillOpen, setTimeTillOpen] = useState(null);
  const [timeTillClose, setTimeTillClose] = useState(null);
  // const [currentTier, setCurrentTier] = useState(null);
  // const [publicMintTime, setPublicMintTime] = useState(null);
  // const [timeTillPublicMint, setTimeTillPublicMint] = useState(null);
  const [onAllowList, setOnAllowList] = useState(false);
  const [allotment, setAllotment] = useState(null);
  const [claimed, setClaimed] = useState(0); // [claimed, setClaimed
  const [claimQuantity, setClaimQuantity] = useState(1);
  const [currentTierName, setCurrentTierName] = useState(null);
  const [nextTierName, setNextTierName] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const requestStatusRef = useRef();
  const isPollingRequestStatus = useRef(false);
  const [requestStatus, setRequestStatus] = useState(null); // ['awaiting_payment', 'paid', 'expired']
  const [paymentAddress, setPaymentAddress] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [inscriptionTx, setInscriptionTx] = useState(null);
  const [walletOpened, setWalletOpened] = useState(false);
  const [satsPerVbyte, setSatsPerVbyte] = useState(1);
  const [fetchingData, setFetchingData] = useState(false);
  const [mintedOut, setMintedOut] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [headerImage, setHeaderImage] = useState("");
  // const [fetching, setFetching] = useState(false);
  // const [fees, setFees] = useState({ low: 0, medium: 0, high: 0 });
  const intervalIdRef = useRef();

  const addNotification = (label, linkUrl) => {
    const message = {
      label: label,
      linkUrl: linkUrl,
    };
    const updatedNotifications = [...notifications, message];
    setNotifications(updatedNotifications);
  };

  const fetchFees = (updateSatsPerVbyte = false) => {
    // Set fetching to true when fees are being fetched
    // setFetching(true);
    fetch("https://mempool.space/api/v1/fees/recommended")
      .then((response) => response.json())
      .then((data) => {
        // setFees({
        //   low: data.hourFee,
        //   medium: data.halfHourFee,
        //   high: data.fastestFee,
        // });
        if (updateSatsPerVbyte) {
          setSatsPerVbyte(Math.max(data.fastestFee, 15));
        }
        // After fees are fetched, set fetching to false
        // setFetching(false);
      })
      .catch((error) => {
        console.error("Error fetching fees:", error);
        // If there's an error, set fetching to false as well
        // setFetching(false);
      });
  };

  useEffect(() => {
    fetchFees(true);
    intervalIdRef.current = setInterval(fetchFees, 30000);

    const timeoutId = setTimeout(() => {
      // setContinueFetching(false);
    }, 300000);

    return () => {
      clearInterval(intervalIdRef.current);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    setCardName(name.replace(/[^a-z0-9-]/gi, ""));
  }, [name]);

  // const customStyles = {
  //   maxWidth: "13px",
  //   display: "inline-block",
  // };

  // const FeeIndicator = () => {
  //   if (!fetching) return null;
  //   return <LoadingGif style={customStyles} />;
  // };
  const doHandleClaim = async (data) => {
    setRequestStatus("init");
    setRequestId(null);
    handleClaim({
      claimQuantity: claimQuantity,
      claimAddress: usersTaprootAddress,
      cardName: cardName,
      satsPerVbyte: satsPerVbyte,
    })
      .then((data) => {
        // console.log("Claimed card:", data);
        addNotification("Claim created...", `/status/${data.request_id}`);
        setRequestId(data.request_id);
        setMinted(data.minted);
        if (data.minted === quantity) {
          setEnabled(false);
        }
        // console.log(data.currentTier.allowList.claimed);
        if (data.currentTier.tier === "Public") {
          setClaimed(0);
        } else {
          setClaimed(data.currentTier.allowList.claimed);
        }
        // console.log(data);
        setClaimQuantity(1);
        // send user to /status/request_id
        // Router.push(`/status/${data.request_id}`);
        navigate(`/status/${data.request_id}`);
      })
      .catch((error) => {
        console.error("Error claiming card:", error);
        alert("Error claiming card, check claim vs supply.");
      });
  };

  const fetchData = async () => {
    setFetchingData(true);
    try {
      const apiKey =
        "477937040d99785807abad70cd9ded0f$21be225af6f928f62f7a9d5b8547c5b82d77de0ffe5388cb07e4783c609e97c1";

      // Fetch data from the endpoint when the component mounts
      const response = await fetch(`${API_URL}/card?name=${cardName}`, {
        headers: {
          "x-api-key": apiKey,
          "connected-address": usersTaprootAddress,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // console.log(data);
      const now = new Date().getTime();
      // setCardName(data.name);
      setEnabled(data.enabled);
      let closesAtTime;
      let opensAtTime;
      if (data.currentTier) {
        setCurrentTierName(data.currentTier.tier);
        if (data.currentTier.tier === "Artifact Mint") {
          setNextTierName("Collector Mint");
        } else if (data.currentTier.tier === "Collector Mint") {
          setNextTierName("CVLT Mint");
        } else if (data.currentTier.tier === "CVLT Mint") {
          setNextTierName("Public");
        }
        if (data.currentTier.closesAt) {
          closesAtTime = new Date(data.currentTier.closesAt).getTime();
          opensAtTime = new Date(data.currentTier.opensAt).getTime();
          setClosesAt(data.currentTier.closesAt);
        } else {
          closesAtTime = new Date(data.closesAt).getTime();
          opensAtTime = new Date(data.opensAt).getTime();
          setClosesAt(data.closesAt);
        }
        setPrice(data.currentTier.price);
      } else {
        setPrice(data.price);
        setClosesAt(data.closesAt);
        closesAtTime = new Date(data.closesAt).getTime();
        opensAtTime = new Date(data.opensAt).getTime();
      }
      setQuantity(data.quantity);
      setMinted(data.minted);
      setMintedOut(data.mintedOut);
      if (mintedOut) {
        setEnabled(false);
      }
      setPreviewImage(data.previewImage);
      setDescription(data.description);
      setOpensAt(data.opensAt);
      const remainingTimeTillOpen = opensAtTime - now;
      setTimeTillOpen(remainingTimeTillOpen);
      if (remainingTimeTillOpen > 0) {
        setShowOpeningCountdown(true);
      }
      const remainingTimeTillClosed = closesAtTime - now;
      setTimeTillClose(remainingTimeTillClosed);
      setOnAllowList(false);
      if (data.displayName) {
        setDisplayName(data.displayName);
      }
      if (data.headerImage) {
        setHeaderImage(data.headerImage);
      }
      if (data.maxPublicClaim) {
        setMaxPublicClaim(data.maxPublicClaim);
      }
      setAllotment(0);
      setClaimed(0);
      if (data.currentTier && data.currentTier.allowList) {
        if (data.currentTier.allowList.address === usersTaprootAddress) {
          setOnAllowList(true);
          setAllotment(data.currentTier.allowList.allotment);
          if (data.currentTier.allowList.claimed) {
            setClaimed(data.currentTier.allowList.claimed);
          } else {
            setClaimed(0);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setFetchingData(false);
  };

  const fetchRequestStatus = () => {
    // console.log("Fetching request status...");
    if (requestId == null) return;
    if (requestStatus === "inscribed") return;

    // fetch using GET, and use the request_id to get the status
    fetch(`${API_URL}/request?request_id=${requestId}`)
      .then((res) => res.json())
      .then(async (data) => {
        // setRequestId(data.request_id);
        setRequestStatus(data.status);

        if (data.status === "awaiting_payment") {
          setPaymentAddress(data.btc_payment_address);
          setPaymentAmount(data.btc_payment_amount);
        }

        if (data.status === "inscribed") {
          setRequestStatus(data.status);
          setInscriptionTx(data.inscription_tx);
        }
      });
  };

  const doPayInvoice = async (paymentAddress, paymentAmount) => {
    setWalletOpened(true);
    PayInvoice(paymentAddress, paymentAmount);
  };

  useEffect(() => {
    // console.log("Request status changed:", requestStatus);
    if (requestStatus === "awaiting_payment" && !walletOpened) {
      addNotification("Claim awaiting payment...");
      doPayInvoice(paymentAddress, paymentAmount);
    }
    if (requestStatus === "confirming_payment") {
      addNotification("Confirming Payment...");
    }
    if (requestStatus === "paid") {
      if (inscriptionTx !== null) {
        addNotification(
          "Claim paid...",
          "View transaction",
          `https://mempool.space/tx/${inscriptionTx}`,
        );
      }
    }
    if (requestStatus === "inscribed" || requestStatus === null) {
      if (inscriptionTx !== null) {
        addNotification("Claim inscribed...", `/status/${requestId}`);
      }
      // console.log("inscribed or null, stop polling...");
      clearInterval(requestStatusRef.current);
      isPollingRequestStatus.current = false; // Reset polling flag when status is inscribed
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStatus]);

  useEffect(() => {
    if (!connected) {
      setAllotment(null);
      setOnAllowList(false);
      return;
    }
    if (!fetchingData) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [cardName, connected, usersTaprootAddress]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const opensAtTime = new Date(opensAt).getTime();
      const remainingTime = opensAtTime - now;
      if (remainingTime <= 0) {
        setShowOpeningCountdown(false);
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [timeTillOpen]);

  useEffect(() => {
    // // Ensure positive `timeTillClose` before creating an interval
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const closesAtTime = new Date(closesAt).getTime();
      const remainingTime = closesAtTime - now;
      if (remainingTime <= 0) {
        fetchData();
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
    // eslint-disable-next-line
  }, [timeTillClose, closesAt]);

  useEffect(() => {
    // console.log("requestId", requestId);

    if (requestId === null || requestStatus === undefined) {
      // console.log("inscribed or null, stop polling...");
      clearInterval(requestStatusRef.current);
      isPollingRequestStatus.current = false; // Reset polling flag when status is inscribed
    } else if (!isPollingRequestStatus.current) {
      requestStatusRef.current = setInterval(fetchRequestStatus, 5000);
      isPollingRequestStatus.current = true; // Mark polling as active
    }

    return () => {
      clearInterval(requestStatusRef.current);
      isPollingRequestStatus.current = false; // Reset polling flag on unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);

  return (
    <div className="container mx-auto w-5/6 font-typewriter text-white md:w-9/12">
      <div className="flex flex-col items-start justify-center md:flex-row">
        <div className="w-full md:m-0 md:mr-8 md:w-2/5">
          {headerImage === "" ? (
            <CardClaimHeaderImg headerImage="/images/counterfeit SERIES 00.png" />
          ) : (
            <CardClaimHeaderImg headerImage={headerImage} />
          )}
          <div
            className="mb-7 ml-2 mt-7 text-center text-2xl"
            style={{ whiteSpace: "pre-line" }}
          >
            {description}
          </div>
          <img src="/images/divider.png" alt="Header" className="m-auto" />
          {enabled && (
            <div className="mb-3 ml-2 mt-7">
              <div className="ml-auto mr-auto grid w-9/12 grid-cols-2 text-center font-typewriter text-lg">
                <div>
                  PRICE:
                  <br /> {((price * claimQuantity) / 100000000).toFixed(6)} BTC
                </div>
                <div>
                  MINTED:
                  <br /> {minted}/{quantity}
                </div>
              </div>
            </div>
          )}
          {enabled ? (
            <div className="mt-5 text-center text-lg">
              {showOpeningCountdown ? (
                <>
                  {currentTierName === null || currentTierName === "Public" ? (
                    <>
                      Claim opens in:
                      <br />
                      <Countdown to={opensAt} />
                    </>
                  ) : (
                    <>
                      {currentTierName} opens in:
                      <br />
                      <Countdown to={opensAt} />
                    </>
                  )}
                </>
              ) : (
                <>
                  {currentTierName !== "Public" && onAllowList && (
                    <div>
                      <div>
                        You have claimed {claimed}/{allotment}:
                        <br />
                        {currentTierName}
                      </div>
                      {claimed < allotment && (
                        <div className="">
                          CLAIM:
                          <select
                            id="claimQuantity"
                            value={claimQuantity}
                            onChange={(e) =>
                              setClaimQuantity(parseInt(e.target.value))
                            }
                            className="m-3 w-20 rounded bg-white px-3 py-2 text-black focus:outline-none focus:ring"
                          >
                            {Array.from(
                              { length: allotment - claimed },
                              (_, index) => index + 1,
                            ).map((count) => (
                              <option key={count} value={count}>
                                {count}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                  )}
                  {connected && currentTierName === "Public" && (
                    <>
                      {requestStatus === "awaiting_payment" ||
                      requestStatus === "pending" ||
                      requestStatus === "init" ? (
                        <button
                          className="mt-4 rounded-md px-4 py-2 text-white"
                          onClick={() => {
                            PayInvoice(paymentAddress, paymentAmount);
                          }}
                          disabled={requestStatus !== "awaiting_payment"}
                        >
                          <LoadingGif />
                        </button>
                      ) : (
                        <>
                          <div className="mb-4">
                            <span className="text-white">CLAIM:</span>
                            <input
                              type="number"
                              id="claimQuantity"
                              value={claimQuantity}
                              onChange={(e) => {
                                const inputValue = parseInt(e.target.value);
                                if (
                                  !isNaN(inputValue) &&
                                  inputValue >= 1 && // Minimum value
                                  inputValue <=
                                    Math.min(quantity - minted, maxPublicClaim) // Maximum value
                                ) {
                                  setClaimQuantity(inputValue);
                                }
                              }}
                              className="m-3 w-20 rounded bg-white px-3 py-2 text-black focus:outline-none focus:ring"
                              min="1" // Set the minimum value allowed
                              max={Math.min(quantity - minted, 5)} // Set the maximum value allowed
                            />
                          </div>
                          <div>
                            <input
                              type="hidden"
                              inputMode="numeric"
                              id="satsPerVbyte"
                              value={satsPerVbyte}
                              required
                              min={15}
                            />
                            <div>
                              <input
                                type="hidden"
                                id="cardName"
                                value={cardName}
                              />
                              <button
                                id="claim"
                                className="mt-4 rounded-md px-4 py-2 text-white"
                                onClick={doHandleClaim}
                              >
                                <img
                                  src="/images/claim-button.png"
                                  width={300}
                                  alt="Claim"
                                />
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {connected && currentTierName !== "Public" && (
                    <div className="mt-4">
                      <input type="hidden" id="cardName" value={cardName} />
                      {currentTierName !== "Public" && !onAllowList && (
                        <div className="mt-4 gap-4 text-center font-typewriter text-lg text-white">
                          You are not on the allow list for this tier.
                        </div>
                      )}
                      {currentTierName !== "Public" && onAllowList && (
                        <div>
                          {claimed < allotment ? (
                            <>
                              <input
                                type="hidden"
                                inputMode="numeric"
                                id="satsPerVbyte"
                                value={satsPerVbyte}
                                required
                                min={15}
                              />
                              <div className="mt-4">
                                {requestStatus === "awaiting_payment" ||
                                requestStatus === "pending" ||
                                requestStatus === "init" ? (
                                  <button
                                    className="mt-4 rounded-md px-4 py-2 text-white"
                                    onClick={() => {
                                      PayInvoice(paymentAddress, paymentAmount);
                                    }}
                                    disabled={
                                      requestStatus !== "awaiting_payment"
                                    }
                                  >
                                    <LoadingGif />
                                  </button>
                                ) : (
                                  <button
                                    id="claim"
                                    className="rounded-md px-4 py-2 text-white"
                                    onClick={doHandleClaim}
                                  >
                                    <img
                                      src="/images/claim-button.png"
                                      width={300}
                                      alt="Claim"
                                    />{" "}
                                  </button>
                                )}
                              </div>
                            </>
                          ) : (
                            <>You have no more claims left on this tier.</>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {!connected && (
                    <div className="mt-4 gap-4 text-center font-typewriter text-lg text-white">
                      Please connect wallet to continue.
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="mt-8 text-center font-typewriter text-lg">
              {mintedOut ? (
                <>Card has minted out!</>
              ) : minted === quantity ? (
                <>
                  Currently sold out.
                  <br /> Please check back shortly.
                </>
              ) : (
                <>Minting not open.</>
              )}
            </div>
          )}
          {closesAt && !showOpeningCountdown && enabled ? (
            <div>
              <div className="mt-4 gap-4 text-center text-lg">
                {currentTierName === null ? (
                  <>
                    Claim closes in:
                    <br />
                    <Countdown to={closesAt} />
                  </>
                ) : (
                  <>
                    {currentTierName === "Public" ? (
                      <>Claim closes in:</>
                    ) : (
                      <>{nextTierName} opens in:</>
                    )}
                    <br />
                    <Countdown to={closesAt} />
                  </>
                )}
              </div>
            </div>
          ) : null}
        </div>

        <div className="mr-8 mt-8 w-full text-center md:ml-8 md:mr-2 md:mt-0 md:w-3/5">
          {previewImage.endsWith(".svg") ? (
            <ReactSVG
              src={previewImage}
              title={displayName}
              beforeInjection={(svg) => {
                svg.setAttribute("style", "height: 800px");
              }}
            />
          ) : (
            <img
              id="inscriptionImg"
              src={previewImage}
              alt={displayName}
              // style={{ width: "100%" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Collection;
