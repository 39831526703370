import { API_URL } from "../config";

const handleClaim = async (data) => {
  // e.preventDefault();
  // console.log("handleClaim called, data: ", data);
  const { claimQuantity, claimAddress, cardName, satsPerVbyte } = data;
  try {
    const apiKey =
      "477937040d99785807abad70cd9ded0f$21be225af6f928f62f7a9d5b8547c5b82d77de0ffe5388cb07e4783c609e97c1";

    const response = await fetch(`${API_URL}/claimCard`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
      body: JSON.stringify({
        claimQuantity,
        claimAddress,
        cardName,
        satsPerVbyte,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error claiming card:", error);
  }
};

export default handleClaim;
